<template>
<div>
<div class="customInfo mt-7">
<div class="info-preview" style="width:480px">
<h4 class="tit">{{ $t('PREVIEW') }}</h4>
<div class="preview">
<img :src="imageContent" class="img-label" />
</div>
</div>
</div>
 <div style="margin-top:176px;">
  <v-row>
       <v-col cols="12" sm="6">
        <div style="padding-top:10px;">{{ $t('Store') }}</div>
          <v-autocomplete   outlined prepend-inner-icon="mdi-magnify" dense  hide-details  v-model="selectedStore" @change="getarticleList(selectedStore,1)" :items="getStoreDetails" ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6">
        <div style="padding-top:10px;">{{ $t('Product') }}</div>
        <!-- <v-autocomplete   outlined prepend-inner-icon="mdi-magnify" dense  hide-details  :placeholder="`${$t('Input Product ID')}`"  v-model="selectedProduct"  @change="getSelectedImagePreview(selectedProduct)"   :items="getarticleListData" ></v-autocomplete> -->

          <v-text-field
            v-model="searchProduct"
            outlined
            dense
            hide-details
            :placeholder="`${$t('Input Product ID')}`"
            class="form-input  in-btn"
            clearable
            style="margin-bottom:10px"
            @input="searchProductValue"
          >
           <template v-slot:append>
          <v-btn @click="handleSearch" text>
            <img src="@/assets/img/btn-search.jpg" alt="" />
          </v-btn>
        </template>
          </v-text-field>
          <v-autocomplete
     return-object
     autocomplete="off"
     outlined prepend-inner-icon="mdi-magnify"
     dense  hide-details
     :placeholder="`${$t('Input Product ID')}`"
     v-model="selectedProduct"
     @change="getSelectedImagePreview(selectedProduct)"
     item-text="articleId"
    item-value="articleId"
    :items="getarticleListData" :no-data-text="`${$t('No data available')}`" >
                <template v-slot:append-item>
                  <div v-intersect="onIntersect" class="pa-4 teal--text">
                     <!-- <span v-if="!moreItemDisable">Loading more items ...</span> -->
                     <div class="loadingBar">
        <v-progress-circular v-if="!moreItemDisable"
          indeterminate
          :size="50"
          :width="7"
          color="#ddd"
        ></v-progress-circular>
      </div>
                  </div>
                </template>

                </v-autocomplete>
                                <!-- <infinite-loading @infinite="getarticleList" slot="append"></infinite-loading> -->

      </v-col>
      </v-row>

<!-- <previewSet :previewData='getpreviewset' :previewSetBtnDisabled="previewSetBtnDisabled" ></previewSet> -->
</div>
</div>
</template>
<script>
// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// import { mapGetters } from 'vuex'
// import l from 'lodash'
// import InfiniteLoading from 'vue-infinite-loading'

// import previewSet from './modal/PreviewSet'
import EventBus from '@/plugins/eventBus'

const requests = {
  getStoreList: {
    method: 'get',
    url: '/api/common/store/simple'
  },
  getArticleList: {
    method: 'get',
    url: '/api/common/articles'
  },
  getImagepreviewData: {
    method: 'get',
    url: '/api/common/templates/preview'
  }
}
export default {
  name: 'SelectedTemplateType',
  components: { },
  props: {

    ImagepreviewSetvalue: [Array, Object, String],
    loadPreviewData: [Array, Object, String],
    getareaKey: [Array, Object, String],
    getareaValue: [Array, Object, String],
    getFileName: [Array, Object, String],
    selectTemplateName: [Array, Object, String],
    selectedAreaKey: [Array, Object, String],
    selectedAreaValue: [Array, Object, String],
    templateAreaClick: [Array, Object, String, Boolean],
    randomValue: [Array, Object, String]

  },
  data () {
    return {
      initaldata: [],
      selected: [],
      selectedCustomerInfoPreview: [],
      image: 'data:image/bmp;base64,',
      previewData: null,
      imagePreview: '',
      previewSetBtnDisabled: true,
      getStoreDetails: [],
      selectedStore: null,
      getarticleListData: [],
      selectedProduct: null,
      imageContent: null,
      selectboxvalue: '',
      page: 0,
      // pages: 0,
      perPage: 10,
      totalpage: 1,
      moreItemDisable: false,
      searchIconClick: false,
      inputSearch: false,
      searchProduct: null
    }
  },
  computed: {
    // ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),

    getImagepreview: function () {
      return this.ImagepreviewSetvalue.contentData
    },
    getpreviewset () {
      return this.templateAreaClick
    },
    getdata () {
      return this.randomValue
      // return this.getareaValue
    }
  },
  watch: {

    getImagepreview () {
      if (this.ImagepreviewSetvalue.contentData !== undefined) {
        this.imageContent = this.image + this.ImagepreviewSetvalue.contentData
        this.previewSetBtnDisabled = false
      } else {
        this.imageContent = ''
        this.previewSetBtnDisabled = true
      }
    },
    getdata () {
      setTimeout(() =>
        this.getStoreList()
      , 1000)
    },
    // store: {
    //   handler () {
    //     this.getStoreList()
    //   }
    // },
    getpreviewset () {
      if (this.templateAreaClick === true) {
        this.getStoreList()
      }
    }
  },

  methods: {
    // get store deatails
    getStoreList () {
      this.getStoreDetails = []
      // console.log(this.selectedAreaKey)
      // console.log(this.selectedAreaValue)

      if (this.getareaKey === '' || this.getareaValue === undefined) {
        this.selectedStore = ''
        // this.imageContent = null
        // this.search = ''
        // this.initaldata = []
        this.getStoreDetails = []
        this.selectedProduct = null

        return
      }

      var selectedAreaKey = ''
      var selectedAreaValue = ''
      if (this.templateAreaClick === false) {
        selectedAreaKey = this.selectedAreaKey
        selectedAreaValue = this.selectedAreaValue
      } else {
        if (this.getareaKey === 'store') {
          selectedAreaKey = this.getareaKey
          selectedAreaValue = this.getareaValue
        } else if (this.selectedAreaKey === 'company' && (this.getareaKey === 'company' || this.getareaKey === 'country' || this.getareaKey === 'region' || this.getareaKey === 'city')) {
          selectedAreaKey = this.getareaKey
          selectedAreaValue = this.getareaValue
        } else if (this.selectedAreaKey === 'country' && (this.getareaKey === 'company' || this.getareaKey === 'country' || this.getareaKey === 'region' || this.getareaKey === 'city')) {
          selectedAreaKey = this.getareaKey
          selectedAreaValue = this.getareaValue
        } else if (this.selectedAreaKey === 'country') {
          selectedAreaKey = this.selectedAreaKey
          selectedAreaValue = this.selectedAreaValue
        } else if (this.selectedAreaKey === 'region') {
          selectedAreaKey = this.selectedAreaKey
          selectedAreaValue = this.selectedAreaValue
        } else if (this.selectedAreaKey === 'city') {
          selectedAreaKey = this.selectedAreaKey
          selectedAreaValue = this.selectedAreaValue
        } else if (this.selectedAreaKey === 'store') {
          selectedAreaKey = this.selectedAreaKey
          selectedAreaValue = this.selectedAreaValue
        }
        // } else if (this.getareaKey === 'company') {
        //   selectedAreaKey = this.selectedAreaKey
        //   selectedAreaValue = this.selectedAreaValue
        // } else if (this.getareaKey === 'country') {
        //   selectedAreaKey = this.selectedAreaKey
        //   selectedAreaValue = this.selectedAreaValue
        // } else if (this.getareaKey === 'region') {
        //   selectedAreaKey = this.selectedAreaKey
        //   selectedAreaValue = this.selectedAreaValue
        // } else if (this.getareaKey === 'city') {
        //   selectedAreaKey = this.selectedAreaKey
        //   selectedAreaValue = this.selectedAreaValue
        // }
      }
      const params = {
        areaKey: selectedAreaKey,
        areaValue: selectedAreaValue
      }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getStoreList.method,
          requests.getStoreList.url,
          config
        )

        .then(res => {
          if (this.loadPreviewData.areaKey === 'store') {
            this.getStoreDetails.push(this.loadPreviewData.areaValue)
            this.selectedStore = this.getStoreDetails[0]
          } else {
            for (var i = 0; i < res.data.storeList.length; i++) {
              this.getStoreDetails.push(res.data.storeList[i].code)
              this.selectedStore = this.getStoreDetails[0]
            }
            if (this.selectboxvalue !== 1 && this.selectboxvalue !== undefined) {
              this.selectedStore = this.getStoreDetails[0]
            }
          }
          this.$emit('getStoreArticleValue', { storeCode: this.selectedStore })
          this.selectedProduct = null
          this.getarticleListData = []
          this.searchProduct = null
          this.searchIconClick = false
          this.totalpage = 1
          this.page = 0
          this.getarticleList(this.selectedStore)
        })
        .catch(error => {
          console.debug(error)
        })
    },
    buildParmas (storeCode) {
      let params
      if (this.searchIconClick === true) {
        params = { id: this.searchProduct, store: storeCode, page: this.page, per_page: this.perPage, sort: 'id.articleId,asc' }
      } else {
        params = { store: storeCode, page: this.page, per_page: this.perPage, sort: 'id.articleId,asc' }
      }
      // const params = { store: storeCode, page: this.page, per_page: this.perPage }
      return params
    },

    // get article details
    getarticleList (storeCode, value) {
      this.selectboxvalue = value
      // this.selectedProduct = null
      // this.getarticleListData = []
      if (this.selectboxvalue === 1) {
        this.page = 1
        this.selectedProduct = null
        this.getarticleListData = []
        this.totalpage = 1
      }
      if (this.inputSearch === true) {
        this.getarticleListData = []
      }

      const params = this.buildParmas(storeCode)
      const config = { params: params }

      if (this.page > this.totalpage) {
        this.moreItemDisable = true
        return
      }
      this.$utils
        .callAxios(
          requests.getArticleList.method,
          requests.getArticleList.url,
          config
        )
        .then(res => {
          this.inputSearch = false
          // for (var i = 0; i < res.data.articleList.length; i++) {
          //   this.getarticleListData.push(res.data.articleList[i].articleId)
          // }
          // this.selectedProduct = this.getarticleListData[0]

          this.totalpage = res.headers['x-totalpages'] - 1

          this.getarticleListData = [
            ...this.getarticleListData,
            ...res.data.articleList
          ]

          if (this.page >= this.totalpage) {
            this.moreItemDisable = true
          }
          this.selectedProduct = this.getarticleListData[0].articleId
          // if (res.data.articleList.length) {
          //   this.page += 1
          //   this.getarticleListData.push(...res.data.articleList)
          //   $state.loaded()
          // } else {
          //   $state.complete()
          // }
          // this.selected.push(this.initaldata[0])

          // this.selected.push(this.initaldata[0])
          console.log(this.selectedProduct)

          this.$emit('getStoreArticleValue', { storeCode: this.selectedStore, articleID: this.selectedProduct })

          if (this.selectboxvalue === 1) {
            setTimeout(() => {
              this.getSelectedImagePreview(this.getarticleListData[0])
            }, 3000)
          }
          if (this.searchIconClick === true) {
            setTimeout(() => {
              this.getSelectedImagePreview(this.getarticleListData[0])
            }, 1000)
          }
        })
        .catch(error => {
          this.imageContent = ''
          console.debug(error)
        })
    },
    onIntersect () {
      console.log('load more...')
      this.page += 1
      // if (this.searchIconClick === true) {
      //   this.pages += 1
      // }
      this.getarticleList(this.selectedStore)
    },

    // get preview deatils based on store
    getSelectedImagePreview (item, row) {
      var article = ''
      this.imageContent = null

      var typeName1 = ''
      if (row === undefined) {
        typeName1 = this.selectTemplateName
      } else {
        typeName1 = item.name
      }
      if (row !== undefined) {
        row.select(true)
      }
      if (item.articleId === undefined) {
        article = this.selectedProduct
      } else {
        article = item.articleId
      }
      // if (this.previewData === '' || this.previewData === null || this.previewData === undefined || this.previewData.length === 0) {
      //   return
      // }
      this.$emit('getStoreArticleValue', { storeCode: this.selectedStore, articleID: item.articleId })

      const params = { areaKey: this.getareaKey, areaValue: this.getareaValue, typeName: typeName1, storeCode: this.selectedStore, articleId: article, fileName: this.getFileName }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getImagepreviewData.method,
          requests.getImagepreviewData.url,
          config
        )
        .then(res => {
          var baseImage = 'data:image/bmp;base64,' + res.data.contentData

          this.imageContent = baseImage
        })
        .catch(error => {
          this.imageContent = ''
          console.log(error.response)
        })
    },
    // handle article search
    handleSearch () {
      this.page = 0
      if (this.searchProduct == null || this.searchProduct === '') {
        EventBus.$emit('messageAlert', 'Please Input Product ID')
        return
      }
      this.searchIconClick = true
      this.inputSearch = true
      this.getarticleList(this.selectedStore)
      // this.getSelectedImagePreview(this.searchProduct)
    },
    searchProductValue () {
      if (this.searchProduct == null && this.searchIconClick === true) {
        this.page = 0
        this.getarticleListData = []
        this.getarticleList(this.selectedStore)
      }
    }

  }

}
</script>
<style scoped>
::v-deep .tit{
  background: lightgrey !important;
  color:black !important
}
::v-deep .preview{
background:grey lighten-4 !important
}
</style>
<style>
.row-class:hover {
  cursor: pointer;
}
/* .active > .text-start {
  background: #031e37 !important;
  color: #ffffff !important;
} */
.customInfo .info-preview .preview {
    justify-content: center;
    align-items: center;
    height: 365px;
    background: #ECECEC;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 44px!important;
    transform: translateZ(0);
}
.slick-prev {
    left:0!important;
        position: absolute;
    z-index: 1;
}
.slick-next{
   right: 0 !important;
}
.slick-prev:before {
    background-image: url('~@/assets/img/previous.png');
    background-size: 20px 28px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content:"";
    margin-left: 18px;
      margin-top: -8px;
}
.slick-next:before {
    background-image: url('~@/assets/img/next.png');
    background-size: 20px 28px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content:"";
    margin-left: -24px;
        margin-top: -9px;

}
    .slick-track {
    position: relative;
    top: 0;
    display: block;
    transform: translateZ(0);
    text-align: -webkit-center !;
}
.container {
    width: 100%;
    padding: 22px!important;
    margin-right: auto;
    margin-left: auto;
}
.slick-dots {
    position: absolute;
    bottom: 36px;
    right: 0;
    display: block;
    width: 100%;
    padding: 0  !important;
    margin: 0;
    list-style: none;
    text-align: center;
}
 .slick-dots li button:before
    {
        font-size: 10px;
        line-height: 10px;
        color: #9c9898;

    }

    .slick-slide img {
    display: block;
    width: 80%  !important;
    height: 169px !important;
    }

    .tagvalues{
    width: 80% !important;
    text-align: center!important;
    font-weight: bold !important;
        font-size: var(--font14);
        color: #4D4F5C !important;
        margin-top: 8px;
    }

</style>
