import { render, staticRenderFns } from "./SelectedTemplateType.vue?vue&type=template&id=285a044c&scoped=true&"
import script from "./SelectedTemplateType.vue?vue&type=script&lang=js&"
export * from "./SelectedTemplateType.vue?vue&type=script&lang=js&"
import style0 from "./SelectedTemplateType.vue?vue&type=style&index=0&id=285a044c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "285a044c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VDataTable,VFileInput,VIcon,VRow,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/-36BVGaF/0/applicationg/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('285a044c')) {
      api.createRecord('285a044c', component.options)
    } else {
      api.reload('285a044c', component.options)
    }
    module.hot.accept("./SelectedTemplateType.vue?vue&type=template&id=285a044c&scoped=true&", function () {
      api.rerender('285a044c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Template/SelectedTemplateType.vue"
export default component.exports