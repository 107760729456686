var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "445", persistent: "", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.addBtnDisabled, text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("New")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.addTemplateVisible,
        callback: function($$v) {
          _vm.addTemplateVisible = $$v
        },
        expression: "addTemplateVisible"
      }
    },
    [
      _c(
        "v-card",
        { key: _vm.addTemplateVisible, staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-template" }),
              _vm._v(_vm._s(_vm.$t("New Template Type")) + " ")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        staticStyle: { "padding-top": "35px" },
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: _vm.$t("Type Name") + " *",
                          placeholder: "" + _vm.$t("Input the type name"),
                          clearable: ""
                        },
                        on: {
                          keypress: _vm.handleKeyUp,
                          keydown: _vm.clearTabindex
                        },
                        model: {
                          value: _vm.template.typeName,
                          callback: function($$v) {
                            _vm.$set(_vm.template, "typeName", $$v)
                          },
                          expression: "template.typeName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0" },
                    [
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          items: _vm.tagTypes,
                          label: _vm.$t("Template Size") + " *",
                          placeholder: "" + _vm.$t("Input the template Size")
                        },
                        on: {
                          keydown: _vm.clearTabindex,
                          change: function($event) {
                            return _vm.handleChangeColor()
                          }
                        },
                        model: {
                          value: _vm.tagType,
                          callback: function($$v) {
                            _vm.tagType = $$v
                          },
                          expression: "tagType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0" },
                    [
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          items: _vm.TempColorItem,
                          label: _vm.$t("Template Color") + " *",
                          placeholder: "" + _vm.$t("Select template color")
                        },
                        on: { keydown: _vm.clearTabindex },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function(data) {
                              return [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#676565" } },
                                      [_vm._v(_vm._s(data.item.split("")[0]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#d1c3c3" } },
                                      [_vm._v(_vm._s(data.item.split("")[1]))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        style:
                                          data.item.split("")[2] === "R"
                                            ? "color:red"
                                            : "color:yellow"
                                      },
                                      [_vm._v(_vm._s(data.item.split("")[2]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "yellow" } },
                                      [_vm._v(_vm._s(data.item.split("")[3]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "orange" } },
                                      [_vm._v(_vm._s(data.item.split("")[4]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "green" } },
                                      [_vm._v(_vm._s(data.item.split("")[5]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "blue" } },
                                      [_vm._v(_vm._s(data.item.split("")[6]))]
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#676565" } },
                                      [_vm._v(_vm._s(data.item.split("")[0]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#d1c3c3" } },
                                      [_vm._v(_vm._s(data.item.split("")[1]))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        style:
                                          data.item.split("")[2] === "R"
                                            ? "color:red"
                                            : "color:yellow"
                                      },
                                      [_vm._v(_vm._s(data.item.split("")[2]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "yellow" } },
                                      [_vm._v(_vm._s(data.item.split("")[3]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "orange" } },
                                      [_vm._v(_vm._s(data.item.split("")[4]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "green" } },
                                      [_vm._v(_vm._s(data.item.split("")[5]))]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "blue" } },
                                      [_vm._v(_vm._s(data.item.split("")[6]))]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.tempColor,
                          callback: function($$v) {
                            _vm.tempColor = $$v
                          },
                          expression: "tempColor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "font-weight": "bold" }
                },
                [_vm._v("(OR)")]
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 col",
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input pr-0",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: _vm.$t("Label type finder") + " *",
                          placeholder: "" + _vm.$t("Input label code"),
                          clearable: ""
                        },
                        on: {
                          keypress: _vm.handleKeyUp,
                          keydown: _vm.clearTabindex
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function() {
                              return [
                                _c("div", { staticClass: "searchIcon" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/btn-search.jpg"),
                                      alt: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.searchLabeltype()
                                      }
                                    }
                                  })
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.labelCode,
                          callback: function($$v) {
                            _vm.labelCode = $$v
                          },
                          expression: "labelCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 mt-0" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input mt-0 pt-0",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          placeholder: "" + _vm.$t(_vm.placeholdertemplatetype),
                          clearable: "",
                          disabled: ""
                        },
                        on: {
                          keypress: _vm.handleKeyUp,
                          keydown: _vm.clearTabindex
                        },
                        model: {
                          value: _vm.templateType,
                          callback: function($$v) {
                            _vm.templateType = $$v
                          },
                          expression: "templateType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.addTemplate }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelAddTemplate }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }