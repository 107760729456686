<template>
  <div>
    <h2 class="page-title-bar"><i class="ico ico-template"></i>{{$t('Template Type List')}}</h2>
    <div class="searchArea w50">
      <v-select
        v-model="selectSearch"
        :items="searchItems"
        item-text="name"
        item-value="value"
        class="form-select grey"
        outlined dense hide-details dark
        @change='searchSelectChange'

      ></v-select>
      <v-text-field
        v-model="searchBy.typeName"
        @keyup.enter="handleSearch()"
        outlined dense hide-details
        :placeholder="`${$t('Input template type name')}`"
        class="form-input ml-2 in-btn"
        clearable
         @click:clear="clearTemplateName()"
        v-show='disabledTemplateName'
      >
      <template v-slot:append>
          <v-btn @click="handleSearch" text>
            <img src="@/assets/img/btn-search.jpg" alt="" />
          </v-btn>
        </template>
      </v-text-field>
       <v-select
        v-model="selectModelType"
        :items="ModelTypeItem"
        class="form-select ml-2"
        outlined dense hide-details
        v-show='disabledModelType'
        :placeholder="`${$t('Select template size')}`"
        @change="searchTemplates1"

      ></v-select>
      <!-- template color search -->
      <v-select
        v-model="selectTempColor"
        :items="selectTempColorItem"
        class="form-select ml-2"
        outlined dense hide-details
        v-show='disabledTempColour'
        :placeholder="`${$t('Select template color')}`"
        @change="searchTemplates1"
      >
      <template  v-slot:selection="data">
        <div  style="font-size: 12px;">
        <span style="color:#676565">{{ data.item.split("")[0] }}</span>
  <span  style="color:#d1c3c3">{{ data.item.split("")[1] }}</span>
  <span :style="data.item.split('')[2]==='R'?'color:red':'color:yellow'">{{ data.item.split("")[2] }}</span>
  <span  style="color:yellow">{{data.item.split("")[3] }}</span>
  <span  style="color:orange">{{ data.item.split("")[4] }}</span>
  <span  style="color:green">{{ data.item.split("")[5] }}</span>
  <span  style="color:blue">{{ data.item.split("")[6] }}</span>
</div>
  </template>
      <template  v-slot:item="data">
        <div style="font-size: 12px;">
        <span style="color:#676565">{{ data.item.split("")[0] }}</span>
  <span  style="color:#d1c3c3">{{ data.item.split("")[1] }}</span>
  <span :style="data.item.split('')[2]==='R'?'color:red':'color:yellow'">{{ data.item.split("")[2] }}</span>
  <span  style="color:yellow">{{data.item.split("")[3] }}</span>
  <span  style="color:orange">{{ data.item.split("")[4] }}</span>
  <span  style="color:green">{{ data.item.split("")[5] }}</span>
  <span  style="color:blue">{{ data.item.split("")[6] }}</span>
</div>
      </template>
    </v-select>

    </div>
<v-row>
<v-col cols="12" sm="4">
    <v-data-table
      v-model="selected"
      :headers="templateHeaders"
      :items="templates"
      item-key="sno"
      :options.sync="options"
      :hide-default-footer="true"
      height="500"
      class="tbl-type01 flat-type02 style03 grayline type3 scroll"
      :items-per-page="2000"
      @click:row="getselectedTemplateType"
      single-select

    >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <!-- color column -->
      <template v-slot:[`item.templateColor`]="{ item }">
        <span style="color:#676565">{{ item.templateColor.split("")[0] }}</span>
  <span  style="color:#d1c3c3">{{ item.templateColor.split("")[1] }}</span>
  <span :style="item.templateColor.split('')[2]==='R'?'color:red':'color:yellow'">{{ item.templateColor.split("")[2] }}</span>
  <span  style="color:yellow">{{ item.templateColor.split("")[3] }}</span>
  <span  style="color:orange">{{ item.templateColor.split("")[4] }}</span>
  <span  style="color:green">{{ item.templateColor.split("")[5] }}</span>
  <span  style="color:blue">{{ item.templateColor.split("")[6] }}</span>

      </template>
      <!-- end -->
    </v-data-table>
    <!-- button group -->
    <div class="table-options">
      <div>
        <AddTemplateType :addBtnDisabled="addBtnDisabled" :user="user" @refreshTemplates="resetTemplates" />
        <v-btn :disabled="deleteBtnDisabled" @click="deleteTemplates()" text class="btn ml-2">{{$t('Delete')}}</v-btn>
      </div>
    </div>
        </v-col>
        <v-col  sm="4">
            <selectedTemplateType :selectedStoreCode='selectedStoreCode' :selectedArticleId='selectedArticleId' @areaClickEvent='areaClickEvent($event)' @getareaKeyValue='getareaKeyValue($event)'  @getpreviewImage ='getpreviewImage($event)' @selectedStoreValue='selectedStoreValue($event)' :checkdeleteFlag="checkdeleteFlag"  :getselectedValue="getSelectedType" :selectedTemplateType='selectedTemplateType'  />
        </v-col>
        <v-col  sm="4">
            <selectedTemplatePreview @getStoreArticleValue='getStoreArticleValue($event)' :getareaKey='getareaKey' :getareaValue='getareaValue' :getFileName='getFileName'  :ImagepreviewSetvalue='ImagepreviewSetvalue' :loadPreviewData="loadPreviewData" :selectTemplateName='selectTemplateName' :selectedAreaKey='selectedAreaKey' :selectedAreaValue='selectedAreaValue' :randomValue='randomValue' :templateAreaClick='templateAreaClick' />
            </v-col>
    </v-row>
    <a ref="link" :style="{ display:'none' }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import EventBus from '@/plugins/eventBus'
// import codes from '@/plugins/codes'
import selectedTemplateType from './SelectedTemplateType'
import AddTemplateType from './modal/AddTemplateType.vue'
import selectedTemplatePreview from './SelectedTemplatePreview'

const requests = {
  getTemplates: {
    method: 'get',
    url: '/api/common/templates/type'
  },
  searchTemplates: {
    method: 'get',
    url: '/api/common/templates/type'
  },
  addTemplate: {
    method: 'post',
    url: '/api/common/templates'
  },
  deleteTemplates: {
    method: 'delete',
    url: '/api/common/templates/type'
  },
  getTagTypes: {
    method: 'get',
    url: '/api/common/templates/data'
  },
  getTemplateColor: {
    method: 'get',
    url: '/api/common/templates/color'
  }
}

export default {
  name: 'TemplateInfo',
  components: {
    AddTemplateType,
    selectedTemplateType,
    selectedTemplatePreview

  },
  data () {
    return {
      user: null,
      selected: [],
      options: {},
      totalTemplates: null,
      templates: [],
      searchBy: { typeName: null },
      addDeleteDisabled: false,
      selectSearch: { name: this.$t('Type Name'), value: 'typeName' },
      searchItems: [{ name: this.$t('Type Name'), value: 'typeName' }, { name: this.$t('Template Size'), value: 'templateSize' }, { name: this.$t('Template Color'), value: 'templateColor' }],
      ModelTypeItem: [],
      disabledModelType: false,
      selectModelType: [],
      // data for colour
      selectTempColor: null,
      selectTempColorItem: [],
      disabledTempColour: false,
      //
      disabledTemplateName: true,
      getSelectedType: '',
      ImagepreviewSetvalue: '',
      sizesByTagTypes: [],
      checkdeleteFlag: false,
      loadPreviewData: [],
      getareaKey: '',
      getareaValue: '',
      selectedStoreCode: '',
      selectedArticleId: '',
      selectTemplateName: '',
      selectedTemplateType: 0,
      getFileName: '',
      selectedAreaKey: '',
      selectedAreaValue: '',
      templateAreaClick: '',
      randomValue: ''

    }
  },
  computed: {
    // display template headers
    templateHeaders () {
      return [
        { text: '#', sortable: false, align: 'center', value: 'sno' },
        { text: this.$t('TYPE NAME'), value: 'typeName' },
        { text: this.$t('TEMPLATE SIZE'), value: 'templateSize' },
        { text: this.$t('COLOR'), value: 'templateColor' }
      ]
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    addBtnDisabled () { return this.addDeleteDisabled },
    deleteBtnDisabled () {
      return (this.selected.length < 1 || this.addDeleteDisabled)
    },
    isSearching () {
      return (this.searchBy.typeName !== null && this.searchBy.typeName.length > 0)
    }
  },
  watch: {
    options: {
      handler () {
        if (this.selectModelType.length !== 0 && this.selectModelType.length !== null) {
          this.searchTemplates1()
        } else if (this.selectTempColor !== 0) {
          this.searchTemplates1()
        } else if (this.isSearching) {
          this.searchTemplates()
        } else {
          // this.getTemplates()
        }
      },

      deep: true
    },
    // store: {
    //   handler () {
    //     this.getTemplates()
    //   }
    // },

    searchBy: {
      handler (newSearchBy) {

      },
      deep: true
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    this.clearTabindex()
    EventBus.$emit('enableSelectedStores', true)
    this.getTagTypes()
    this.getTempColor()
    // if (this.store === undefined || !this.store.code) {
    if (!this.store.company) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getTemplates()
    }

    this.$store.dispatch('auth/getDisabledBtn', '5100').then(flag => {
      this.addDeleteDisabled = flag
    })
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    // clear search typename
    clearTemplateName () {
      this.searchBy.typeName = null
      this.getTemplates()
    },
    // get template size
    setSizesByTagTypes (tagTypeList) {
      const sizesByTagTypes = {}
      for (const tagType of tagTypeList) {
        sizesByTagTypes[tagType.name] = tagType
      }
      this.sizesByTagTypes = sizesByTagTypes
    },
    getTagTypes () {
      const params = { company: this.user.company }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getTagTypes.method,
          requests.getTagTypes.url,
          config
        )
        .then(res => {
          this.setSizesByTagTypes(res.data.tagTypeList)
          this.ModelTypeItem = res.data.tagTypeList.map(tl => tl.name)
        })
    },
    // get template color
    getTempColor () {
      const params = { company: this.user.company }
      const config = { params: params }

      this.$utils
        .callAxios(
          requests.getTemplateColor.method,
          requests.getTemplateColor.url,
          config
        )
        .then(res => {
          this.selectTempColorItem = res.data.tagColorList
        })
    },
    // end

    // prop value to load image preview used for selectedpreview page
    getpreviewImage (item) {
      this.loadPreviewData = item.areaData
      this.ImagepreviewSetvalue = item.imageContent
      this.selectTemplateName = item.templateName
    },
    selectedStoreValue (item) {
      this.selectedAreaKey = item.selectedAreaKey
      this.selectedAreaValue = item.selectedAreaValue
      this.randomValue = item.randomvalue
    },
    // load preview image based on area key and value
    getareaKeyValue (item) {
      this.getareaKey = item.getareaDetails.areaKey
      this.getareaValue = item.getareaDetails.areaValue
      this.getFileName = item.getareaDetails.templateName
    },
    areaClickEvent (item) {
      this.templateAreaClick = item.templateAreaClick
    },
    // get store and artilce name to load preview image when click on template type
    getStoreArticleValue (item) {
      this.selectedStoreCode = item.storeCode
      this.selectedArticleId = item.articleID
    },
    // change event for select search
    searchSelectChange () {
      if (this.selectSearch === 'typeName') {
        this.disabledModelType = false
        this.disabledTemplateName = true
        this.disabledTempColour = false
        this.clearTemplateName()
        this.selectModelType = null
        this.selectTempColor = null
      } else if (this.selectSearch === 'templateColor') {
        this.disabledModelType = false
        this.disabledTemplateName = false
        this.disabledTempColour = true
        this.clearTemplateName()
        this.selectModelType = []
      } else {
        this.disabledModelType = true
        this.disabledTemplateName = false
        this.disabledTempColour = false
        this.clearTemplateName()
        this.selectTempColor = null
      }
    },
    // table row click
    getselectedTemplateType (item, row) {
      row.select(true)
      this.getSelectedType = item
      this.selectedTemplateType = 1
    },
    // rest option
    resetTemplates () {
      if (this.selectModelType.length !== 0) {
        this.searchTemplates1()
      } else if (this.isSearching) {
        this.searchTemplates()
      } else {
        this.getTemplates()
      }

      this.selected = []
    },

    // response for gettemplate
    handleGetTempaltesResponse (res) {
      if (res.status === 204) {
        this.handleGetTempaltesError('No content')
        return
      }
      this.selected = []
      if (res.status === 200) {
        this.templates = []
        res.data.forEach((item, index) => {
          item.sno = index + 1
          this.templates.push(item)
        })
        // this.templates = res.data
        if (res.data.length !== 0) {
          this.selected.push(this.templates[0])
        }
        this.totalTemplates = this.templates.length
        this.getSelectedType = this.templates[0]
      }
    },
    handleGetTempaltesError (error) {
      this.templates = []
      this.totalTemplates = this.templates.length
      this.selected = []
      console.debug(error)
    },
    getTemplateSortKey (sortKey) {
      switch (sortKey) {
        case 'typeName': sortKey = 'typeName'
          break
        case 'templateSize': sortKey = 'templateSize'
          break
        case 'templateColor': sortKey = 'templateColor'
          break
        default: break
      }
      return sortKey
    },
    buildParmas: function () {
      const { sortBy, sortDesc } = this.options
      const params = { }
      let sort = null
      if (sortBy.length === 1 && sortDesc.length === 1) {
        const sortKey = this.getTemplateSortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      params.size = 2000
      if (this.isSearching) {
        params.typeName = this.searchBy.typeName
      }
      return params
    },

    getTemplates () {
      const params = this.buildParmas()
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getTemplates.method,
          requests.getTemplates.url,
          config
        )
        .then(res => {
          this.handleGetTempaltesResponse(res)
        })
        .catch(error => {
          this.handleGetTempaltesError(error)
        })
    },
    handleSearch () {
      this.searchTemplates()
    },
    searchTemplates () {
      if (!this.isSearching) {
        this.getTemplates()
        return
      }
      const params = this.buildParmas()
      // params = { typeName: this.searchBy.typeName }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getTemplates.method,
          requests.getTemplates.url,
          config
        )
        .then(res => {
          this.handleGetTempaltesResponse(res)
        })
        .catch(error => {
          this.handleGetTempaltesError(error)
        })
    },
    searchTemplates1 () {
      let params
      if (this.selectModelType.length === 0) {
        params = { templateColor: this.selectTempColor, size: '2000' }
      } else {
        params = { templateSize: this.selectModelType, size: '2000' }
      }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getTemplates.method,
          requests.getTemplates.url,
          config
        )
        .then(res => {
          this.handleGetTempaltesResponse(res)
        })
        .catch(error => {
          this.handleGetTempaltesError(error)
        })
    },
    deleteTemplates: function () {
      const params = { typeName: this.selected[0].typeName, templateSize: this.selected[0].templateSize, templateColor: this.selected[0].templateColor }
      const config = { params: params }

      if (this.selected.length < 1) return
      this.$utils
        .callAxios(
          requests.deleteTemplates.method,
          requests.deleteTemplates.url,
          config,
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8'
            }
          }

        )

        .then((res) => {
          const result = res.data

          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
            this.selected = []
            this.resetTemplates()
          } else if (res.status === 406) {
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to delete '))
          }
          this.checkdeleteFlag = true
        }).catch(error => {
          const result = error.response.data
          if (error.response.status === 406) {
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to delete'))
          }
          console.debug(error)
        })
    }
  }
}
</script>
<style scoped>
.pageAlign{
  float:right;

}
.w50{
    width:32% !important
}
::v-deep .form-select.v-text-field--outlined {
    padding-top: 0px !important;
}
::v-deep .tbl-type01.style03
th {
    background: #ececec !important;
}
::v-deep .form-input.v-text-field--outlined{
    padding-top: 0px !important;
}
::v-deep .highlight {
     background-color: red;
}
::v-deep tr:hover{
     cursor: pointer;
}
::v-deep tr.v-data-table__selected td{
  background: #09263f !important
}
::v-deep tr.v-data-table__selected td{
  color: #ffffff !important
}
::v-deep .type3{
    border: 1px solid lightgray;
}
::v-deep .tbl-type01.scroll{
  overflow: hidden !important;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper{
  /* overflow-x: hidden; */
}
::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr>th{
  white-space: normal !important;
}
::v-deep .form-select.v-text-field--outlined .v-select__selections{
  display: flex !important;
}
::v-deep ::-webkit-scrollbar:horizontal{
  height: 6px;
  width: 6px;
}
::v-deep ::-webkit-scrollbar-thumb:horizontal{
  background: #818181;
  border-radius: 5px;
}

</style>
